const AuthRoutes = {};

const NonAuthRoutes = {
  landingPage: "/",
  home: "/home",
  ourStory: "/our-story",
  play: "/play",
  munch: "/munch",
  contact: "/contact",
  cart: "/cart",
};

export { AuthRoutes, NonAuthRoutes };
