import React, { useEffect } from "react";

function ComingSoon() {
  useEffect(() => {
    const ac = new AbortController();
    document.title = "Skate City • Home";
    window.scrollTo({ top: 0, behavior: "auto" });
    return function cleanup() {
      ac.abort();
    };
  }, []);

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <p className="text-[40px] font-OpenSansSemiBold text-center  w-[90%]">
        <span className="font-NewyearGoo text-skatecityng-purple">Skate City</span> is Rebuilding it&apos;s website! 🎉
      </p>
    </div>
  );
}

export default ComingSoon;
