const localStorageHandler = {
  setCart(cart) {
    localStorage.setItem("cart", cart);
  },
  getCart() {
    return localStorage.getItem("cart");
  },
  deleteCart() {
    return localStorage.removeItem("cart");
  },
};

export default localStorageHandler;
